<template>
    <div class="row justify-content-center">
        <div class="d-print-none d-block">
        <div class="container-fluid col-md-12" style="background:rgb(248,249,250); border-radius: 10px; box-shadow: 5px 5px 5px 5px #999999;" id="contenedor">
            <!-- <div class="d-flex justify-content-center">
							<div class="row">
								<div class="col">
								<br>
								<img src="https://seguimiento.cmimbral.cl/assets/logo_tienda.png"  width="200" class="img-fluid" alt="Responsive image"><br>
								</div>
							</div>
							
            </div>-->
            
            <br>
            <h3 class="text-center"><b>Ingresar Pedido</b></h3><br>
            <div class="input-group mb-6">
                <input type="text" class="form-control " placeholder="N° pedido" style="border-color:rgb(237,126,37)" id="pedido" v-model="pedido" >
                <div class="input-group-append">
                    <button  id="btnBuscar" class="btn btn-primary" @click="buscarPedido2()"  style="background-color:rgb(0,56,143); border-color:rgb(237,126,37)"><i class="fas fa-search "></i></button>
                        </div>
            </div><br>
           <!--<form enctype="multipart/form-data" @submit.prevent="onFormSubmit">--> 
            
                <div class="row">
                     <div class="col-sm-6">
                        <div class="form-group">
                        <label>Cliente</label>
                        <input type="text" id="cliente" v-model="cliente" class="form-control" placeholder="Ingrese nombre" style="border-color:rgb(237,126,37)"  readonly>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                        <label>RUT</label>
                        <input type="text" id="rut" v-model="rut" class="form-control" placeholder="Ingrese RUT" style="border-color:rgb(237,126,37)"  readonly>
                        </div>
                    </div>
                </div><br>

                <div class="row">
                     <div class="col-sm-6">
                        <div class="form-group">
                        <label>Telefono</label>
                        <input type="text" id="telefono" v-model="telefono" class="form-control" placeholder="Ingrese telefono" style="border-color:rgb(237,126,37)"  readonly>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                        <label>Envio</label>
                        <input type="text" id="envio" v-model="envio" class="form-control" placeholder="Ingrese envío" style="border-color:rgb(237,126,37)"  readonly>
                        </div>
                    </div>
                </div><br>
                <div class="row">
                     <div class="col-sm-6">
                        <div class="form-group">
                        <label>Dirección</label>
                        <input type="text" id="direccion" v-model="direccion" class="form-control" placeholder="Ingrese dirección" style="border-color:rgb(237,126,37)"  readonly>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                        <label>Total (con descuentos y envío)</label>
                        <input type="text" id="total" v-model="total" class="form-control" placeholder="Ingrese total" style="border-color:rgb(237,126,37)"  readonly>
                        </div>
                    </div>
                </div><br>
                <div class="row">
                     <div class="col-sm-6">
                        <div class="form-group">
                        <label>Documento</label>
                        <input type="text" id="documento" v-model="documento" class="form-control" placeholder="Ingrese documento" style="border-color:rgb(237,126,37)"  readonly>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                        <label>Fecha</label>
                        <input type="text" id="fechaVenta" v-model="fechaVenta" class="form-control" placeholder="Ingrese fecha" style="border-color:rgb(237,126,37)"  readonly>
                        </div>
                    </div>
                   
                </div><br>

            <div class="row">
                <div class="col-sm">
                    <h6>Items</h6>
                            <table class="table table-striped table-responsive" style="display:block" id="tabla1">
                    <thead style="background-color:rgb(0,58,140);color:white;font-size: 12px;" >
                        <tr>
                            <th>IMAGEN</th>
                            <th>SKU</th>
                            <th>NOMBRE</th>
                            <th>CANTIDAD</th>
                            <th>PRECIO</th>
                             <!--  <th>SELECCIONAR</th>
                         <th>N.C</th>-->
                        
                        </tr>
                    </thead>
                    <tbody style="font-size: 12px;">
                        <tr v-for="x in Items" :key="x.key">
                        <td><img :src="x.imageUrl" alt="Imagen del producto" /></td>
                        <td>{{ x.refId }}</td>
                        <td>{{ x.name }}</td>
                        <td>{{ x.quantity }}</td>
                        <td>{{ x.sellingPrice.toString().slice(0, -2) }}</td>
                      <!--    <td><button class="btn btn-success btn-sm" @click="seleccionarItem(x)">Patio</button></td>
                      <td><button class="btn btn-danger btn-sm" @click="nc1(x)">N.C</button></td>-->
                </tr>
                    </tbody>
                    </table>
                </div>
              <!--
                <div class="col-sm-6">
                    <h6>Para patio</h6>
                            <table class="table table-striped table-responsive" style="display:block" id="tabla1">
                    <thead style="background-color:rgb(0,58,140);color:white;font-size: 12px;">
                        <tr>
                            <th>IMAGEN</th>
                            <th>SKU</th>
                            <th>NOMBRE</th>
                            <th>CANTIDAD</th>
                            <th>PRECIO</th>
                            <th>SELECCIONAR</th>
                        
                        
                        </tr>
                    </thead>
                    <tbody style="font-size: 12px;">
                        <tr v-for="x in Seleccionados" :key="x.key">
                        <td><img :src="x.imageUrl" alt="Imagen del producto" /></td>
                        <td>{{ x.refId }}</td>
                        <td>{{ x.name }}</td>
                        <td>{{ x.quantity }}</td>
                        <td>{{ x.sellingPrice.toString().slice(0, -2) }}</td>
                        <td><button class="btn btn-warning btn-sm" @click="seleccionarItem2(x)">V.O</button></td>
                       
                </tr>
                    </tbody>
                    </table>
  
                </div>-->
            </div>

              
<!--
            <div class="d-flex justify-content-center">
				<div class="row">
					<div class="col"><br>
                        <h6>Nota de Crédito</h6>
                            <table class="table table-striped table-responsive" style="display:block" id="tabla1">
                    <thead style="background-color:rgb(0,58,140);color:white;font-size: 12px;">
                        <tr>
                            <th>IMAGEN</th>
                            <th>SKU</th>
                            <th>NOMBRE</th>
                            <th>CANTIDAD</th>
                            <th>PRECIO</th>
                        
                        </tr>
                    </thead>
                    <tbody style="font-size: 12px;">
                        <tr v-for="x in NC" :key="x.key">
                        <td><img :src="x.imageUrl" alt="Imagen del producto" /></td>
                        <td>{{ x.refId }}</td>
                        <td>{{ x.name }}</td>
                        <td>{{ x.quantity }}</td>
                        <td>{{ x.sellingPrice.toString().slice(0, -2) }}</td>
                    
                </tr>
                    </tbody>
                    </table>
						</div>
					</div>
							
                </div>
                <br>
        

            -->
        
                
               <!-- <div class="row">
                    <div class="col-sm">
                      <label>Seleccionar archivo</label>
                        <input style="border-color:rgb(237,126,37)" id="archivo" @change="clickImagen($event)" type="file" accept="file/*" ><br>
                </div>
                </div>-->
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                        <label for="observacion">Observación</label>
                        <textarea class="form-control" id="observacion" v-model="observacion" rows="3" placeholder="Ingrese caracteristicas, elementos necesarios, etc."  style="border-color:rgb(237,126,37)"></textarea><br>
                    </div>
                    </div>
                </div>
                <!--<div class="row">
                    <div class="form-group"><br>
                        <label for="observacion">Observacion</label>
                        <textarea class="form-control" id="observacion" rows="3" v-model="cheque.observacion" style="border-color:rgb(237,126,37)"></textarea><br>
                    </div>
                </div>-->
               <!-- <div class="row" >
                    <div class="col-sm-6">
                        <input style="border-color:rgb(237,126,37)" @change="clickImagen($event)" type="file" accept="image/*" >
                    </div>

                </div>--><br>
                <div class="d-flex justify-content-center">
                    <div class="form-group">
                        <button class="btn btn-primary btn-block" id="btnRegistrar" style="background-color: rgb(0, 56, 143); color: white; border-color: rgb(237, 126, 37);" @click="registrar()">Registrar</button><br><br>
                    </div>
                </div>

          <!--  </form>--> 
        </div>
        </div>
        <div class="d-print-block d-none">
        <div class="row" style="font-size: 12px;">
            <div class="col-sm-4">
                <b>Pedido: </b>{{ pedido }} 
            </div>
            <div class="col-sm-4">
                <b>Cliente: </b>{{cliente }} 
            </div>
            <div class="col-sm-4">
                <b>RUT: </b>{{ rut }}
            </div>
        </div>
        <div class="row" style="font-size: 12px;">
            <div class="col-sm-4">
                <b>Teléfono: </b>{{ telefono }}
            </div>
            <div class="col-sm-4">
                <b>Entrega: </b>{{ envio }}
            </div>
            <div class="col-sm-4">
                <b>Precio Entrega: </b>{{ precioEnvio }}
            </div>
        </div>
        <div class="row" style="font-size: 12px;">
            <div class="col-sm-6">
                <b>Dirección: </b>{{ direccion }}
            </div>
            <div class="col-sm-6">
                <b>Fecha Venta: </b>{{ fechaVenta }}
            </div>
        </div>
          <!-- <div class="row">
                <div class="col">
                    <p style="font-weight: 100;"><b>Pedido:</b>{{ pedido }} | <b>Cliente:</b>{{cliente }} | <b>RUT:</b>{{ rut }} | <b>Teléfono:</b> {{ telefono }} | <b>Entrega: </b>{{ envio }} | 
                        <b>Dirección:</b>{{ direccion }} | 
                    </p>
                </div>
            </div>--> 
           

            <div class="row">
                <div class="col-sm">
                    <h6>Items</h6>
                            <table class="table table-striped table-responsive" style="display:block" id="tabla1">
                    <thead style="background-color:rgb(0,58,140);color:white;font-size: 12px;" >
                        <tr>
                            <th>IMAGEN</th>
                            <th>SKU</th>
                            <th>NOMBRE</th>
                            <th>CANTIDAD</th>
                            <th>PRECIO</th>
                        
                        </tr>
                    </thead>
                    <tbody style="font-size: 12px;">
                        <tr v-for="x in Items" :key="x.key">
                        <td><img :src="x.imageUrl" alt="Imagen del producto" /></td>
                        <td>{{ x.refId }}</td>
                        <td>{{ x.name }}</td>
                        <td>{{ x.quantity }}</td>
                        <td>{{ x.sellingPrice.toString().slice(0, -2) }}</td>
                </tr>
                    </tbody>
                    </table>
                </div>
            </div>
<br><!--
            <div class="row">
                <div class="col-sm">
                    <h6>Items Patio</h6>
                            <table class="table table-striped table-responsive" style="display:block" id="tabla2">
                    <thead style="background-color:rgb(0,58,140);color:white;font-size: 12px;" >
                        <tr>
                            <th>IMAGEN</th>
                            <th>SKU</th>
                            <th>NOMBRE</th>
                            <th>CANTIDAD</th>
                            <th>PRECIO</th>
                        
                        </tr>
                    </thead>
                    <tbody style="font-size: 12px;">
                        <tr v-for="x in Seleccionados" :key="x.key">
                        <td><img :src="x.imageUrl" alt="Imagen del producto" /></td>
                        <td>{{ x.refId }}</td>
                        <td>{{ x.name }}</td>
                        <td>{{ x.quantity }}</td>
                        <td>{{ x.sellingPrice.toString().slice(0, -2) }}</td>
                </tr>
                    </tbody>
                    </table>
                </div>
            </div>-->
        </div>
    </div>
</template>
<script src='https://unpkg.com/v-calendar'></script>
<script>
    //import firebase from "firebase/app";
    import axios from 'axios';
    import { db } from '../firebaseDb';
    import {storage} from '../firebaseDb';
    import Swal from 'sweetalert2'
    var sd = require('silly-datetime');

    const ref=storage.ref()
   
    export default {
         
        data() {
            return {
                capacitacion: {
                },
                imagen:null,
                imagenes:[],
                usuario:'',
                fecha:'',
                hora:'',
                semanaFecha:'',
                Items:[],
                tipo:'CAPACITACION',
                rut:'',
                cliente:'',
                telefono:'',
                envio:'',
                direccion:'',
                total:'', 
                pedido:'',  
                Seleccionados:[],
                NC:[],
                urlBd:'https://api.cmimbral.cl/',
                observacion:'',
                documento:'',
                fechaVenta:'',
                precioEnvio:''
            
                
            }
        },
        created() {
            document.getElementById("nav").style.display="block";
            this.usuario=localStorage.usuario;
            this.validarUsuario();
            this.obtenerFecha();
        },
        methods: {
            seleccionarItem(item) {
                console.log(item)
                var x=this.Items.indexOf(item);
                if (this.Seleccionados.indexOf(item) === -1) {
                    this.Seleccionados.push(item);
                    this.Items.splice(x,1)
                    console.log("La nueva colección: " + this.Seleccionados);
                } else if (this.Seleccionados.indexOf(item) > -1) {
                    console.log(this.Seleccionados + " ya existe en la colección.");
                }
            },
            seleccionarItem2(item) {
                console.log(item)
                var x=this.Seleccionados.indexOf(item);
                if (this.Items.indexOf(item) === -1) {
                    this.Items.push(item);
                    this.Seleccionados.splice(x,1)
                    console.log("La nueva colección: " + this.Items);
                } else if (this.Items.indexOf(item) > -1) {
                    console.log(this.Items + " ya existe en la colección.");
                }
            },
            nc1(item) {
                if(confirm("¿Está seguro de solicitar la nota de crédito de este item?")){
                    console.log(item)
                    var x=this.Items.indexOf(item);
                    if (this.NC.indexOf(item) === -1) {
                        this.NC.push(item);
                        this.Items.splice(x,1)
                        console.log("La nueva colección: " + this.NC);
                    } else if (this.NC.indexOf(item) > -1) {
                        console.log(this.NC + " ya existe en la colección.");
                    }
                }
                
            },

            nc2(item) {
                if(confirm("¿Está seguro de solicitar la nota de crédito de este item?")){
                    console.log(item)
                    var x=this.Seleccionados.indexOf(item);
                    if (this.NC.indexOf(item) === -1) {
                        this.NC.push(item);
                        this.Seleccionados.splice(x,1)
                        console.log("La nueva colección: " + this.NC);
                    } else if (this.NC.indexOf(item) > -1) {
                        console.log(this.NC + " ya existe en la colección.");
                    }
                }
            },
            buscarPedido2(){
                var pedido=document.getElementById("pedido").value
                if(pedido!=""){
                    this.Items=[]
                    this.Seleccionados=[]
                    this.NC=[]
                fetch('https://back.cmimbral.cl/pedido/'+pedido)
                    .then((response) => {
                        if (!response.ok) {
                        throw new Error('La solicitud no tuvo éxito.');
                        }
                        return response.json(); // Analiza la respuesta como JSON
                    })
                    .then((data) => {
                        console.log(data);
                        this.cargarDatos(data)
                        // Haz lo que necesites con los datos
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

                }else{
                    alert("ingrese pedido")
                }
            },

            
            

            cargarDatos(data){
                this.Items=data.items
                this.rut=data.clientProfileData.document
                this.cliente=data.clientProfileData.firstName+" "+data.clientProfileData.lastName
                this.telefono=data.clientProfileData.phone
                this.envio=data.shippingData.logisticsInfo[0].deliveryCompany
                var precioEnvio=data.totals[2].value
                this.precioEnvio=precioEnvio.toString().slice(0, -2)
                this.direccion=data.shippingData.address.street+", "+data.shippingData.address.number+", "+data.shippingData.address.neighborhood+", "+data.shippingData.address.state
                const valorTotal=data.totals[0].value+data.totals[1].value+data.totals[2].value+data.totals[3].value
                const valorTotal2=valorTotal.toString().slice(0, -2)
                const valorTotal3=parseInt(valorTotal2)
                const formateado = valorTotal3.toLocaleString("es", {
                    style: "currency",
                    currency: "CLP"
                });
                console.log(valorTotal,valorTotal2,formateado)
                this.total=formateado//valorTotal.toString().slice(0, -2)
                this.documento=(data.clientProfileData.isCorporate==false?'Boleta':'Factura a '+data.clientProfileData.corporateName+" "+data.clientProfileData.corporateDocument)
                this.fechaVenta=data.invoicedDate.split("T")[0]
            },
            registrar(){
                let y=this
                var x = {
                        //sku,nombre,cantidad,precio,pedido,tipo
                        numero:this.pedido.replaceAll(' ',''),
                        cliente:this.cliente,
                        rut: this.rut,
                        telefono:this.telefono,
                        envio:this.envio,
                        direccion:this.direccion,
                        total:this.total,
                        documento:this.documento,
                        observacion:this.observacion,
                        fechaVenta:this.fechaVenta
                        
                    };

                fetch('https://api.cmimbral.cl/registrarPedido', {
                        method: 'post',
                        headers: {
                               'Content-Type': 'application/json' // Establece el tipo de contenido como JSON
                        },
                        //mode:'no-cors',
                        body: JSON.stringify(x) 
                
                })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    if(data.message.code=="ER_DUP_ENTRY"){
                        Swal.fire({
                                title: "Error",
                                text: "Pedido ya registrado",
                                icon: "error",
                        })
                    } else if(data.message="Pedido registrado exitosamente"){
                       /* if(y.Items.length>0){
                           
                            //setTimeout(() => {
                                y.registrarItemsVO()   
                            //}, 3500)
                        }
                        if(y.Seleccionados.length>0){
                           
                            //setTimeout(() => {
                                y.registrarItemsPT()
                            //}, 3500)
                        }*/
                        setTimeout(() => {
                            y.Items=[]
                            y.Seleccionados=[]
                            y.rut=''
                            y.cliente=''
                            y.telefono=''
                            y.envio=''
                            y.direccion=''
                            y.total=''
                            y.documento=''
                            y.observacion=''
                            y.pedido=''
                            y.fechaVenta=''
                        }, 3000)
                        window.print()
                        Swal.fire({
                                title: "Listo",
                                text: "Pedido Registrado",
                                icon: "success",
                        }) 
                        
                    }else{
                        Swal.fire({
                                title: "Error",
                                text: data.message.code
                        }) 
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            },

            registrarItemsVO(){
                //console.log(this.Items)
                let y=this
                var data=this.Items
                data.forEach(function(dato){
                    console.log(dato)
                    var x = {
                        //sku,nombre,cantidad,precio,pedido,tipo
                        sku: dato.refId,
                        nombre:dato.name,
                        cantidad:dato.quantity,
                        precio:dato.sellingPrice.toString().slice(0, -2),
                        pedido:y.pedido,
                        imagen:dato.imageUrl,
                        tipo:'VO'
                        
                    };

                fetch('https://api.cmimbral.cl/registrarItems', {
                        method: 'post',
                        headers: {
                               'Content-Type': 'application/json' // Establece el tipo de contenido como JSON
                        },
                        //mode:'no-cors',
                        body: JSON.stringify(x) 
                
                })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                })
                .catch(error => {
                    console.error('Error:', error);
                });

                })
                //1- Registrar PEDIDO
                //2- REGISTRAR DETALLE DE LOS ITEMS.
            },

            registrarItemsPT(){
                //console.log(this.Items)
                let y=this
                var data=this.Seleccionados
                data.forEach(function(dato){
                    console.log(dato)
                    var x = {
                        //sku,nombre,cantidad,precio,pedido,tipo
                        sku: dato.refId,
                        nombre:dato.name,
                        cantidad:dato.quantity,
                        precio:dato.sellingPrice.toString().slice(0, -2),
                        pedido:y.pedido,
                        imagen:dato.imageUrl,
                        tipo:'PT'
                        
                    };

                fetch('https://api.cmimbral.cl/registrarItems', {
                        method: 'post',
                        headers: {
                               'Content-Type': 'application/json' // Establece el tipo de contenido como JSON
                        },
                        //mode:'no-cors',
                        body: JSON.stringify(x) 
                
                })
                .then(response => response.json())
                .then(data => {
                     console.log(data)
                })
                .catch(error => {
                    console.error('Error:', error);
                });

                })
                //1- Registrar PEDIDO
                //2- REGISTRAR DETALLE DE LOS ITEMS.
            },

            onFormSubmit(event) {
                event.preventDefault()
               //this.guia.usuario=this.usuario;
                //this.capacitacion.fecha=this.fecha;
                //this.capacitacion.hora=this.hora;
                //this.capacitacion.estado='PENDIENTE';
                this.capacitacion.nombreProveedor = document.getElementById("nombreProveedor").value.toUpperCase()
                //this.capacitacion.rutProveedor = document.getElementById("rutProveedor").value
                this.capacitacion.fecha = document.getElementById("fecha").value
                var fecha2=document.getElementById("fecha").value.split("-")
                var añofecha2=fecha2[0];
                var mesfecha2=fecha2[1];
                var diafecha2=fecha2[2];
                this.capacitacion.frcapacitacion=añofecha2+mesfecha2+diafecha2;
                this.capacitacion.hora = document.getElementById("hora").value
                this.capacitacion.participantes = document.getElementById("participantes").value//.toUpperCase() 
                this.capacitacion.relator = document.getElementById("relator").value.toUpperCase()
                this.capacitacion.persona = document.getElementById("persona").value.toUpperCase()
                this.capacitacion.lugar = document.getElementById("lugar").value.toUpperCase()
                this.capacitacion.imagen="NO"
                this.capacitacion.observacion = document.getElementById("observacion").value.toUpperCase()
                this.capacitacion.fr = this.año+this.mes+this.dia
                this.capacitacion.fecha =diafecha2+"-"+mesfecha2+"-"+añofecha2;
                this.capacitacion.usuarioRegistro = this.usuario;
                this.capacitacion.semanaFecha = this.semanaFecha;
                this.capacitacion.tipo = this.tipo;
                db.collection('capacitaciones').add(this.capacitacion).then(() => {
                    Swal.fire({
                    title: "Listo",
                    text: "Evento Registrado",
                    icon: "success",
                    })
                   // this.subirImagen();
                document.getElementById("nombreProveedor").value="";
               // document.getElementById("rutProveedor").value="";
                document.getElementById("fecha").value="";
                document.getElementById("hora").value="";
                document.getElementById("participantes").value="";
                document.getElementById("relator").value="";
                document.getElementById("persona").value="";
                document.getElementById("lugar").value="";
                document.getElementById("observacion").value="";
                this.camposMail()
                  this.testMail();  
                  this.testMail2();  
                }).catch((error) => {
                    console.log(error);
                });

            
            },
            clickImagen(e){
               this.imagen=e.target.files[0];  
               console.log(this.imagen)   
            },
            subirImagen(){
                //referencia de subida
                try{
                  const refImg=ref.child('archivos/'+this.imagen.name)
                    const metadata={contentType:'img/jpeg'}
                    refImg.put(this.imagen,metadata)
                    .then(e=>console.log(e)) 
                     document.getElementById("archivo").value="";
                        this.imagen=null;     
                }catch(error){
                    console.log(error)
                }
            },
        obtenerFecha(){
            this.fecha=sd.format(new Date(), 'DD-MM-YYYY');
            this.dia=(sd.format(new Date(), 'DD'))
            this.mes=(sd.format(new Date(), 'MM'))
            this.año=(sd.format(new Date(), 'YYYY'))
            this.hora=sd.format(new Date(), 'HH:mm');
        },
        validarUsuario(){
            if(this.usuario==undefined || this.usuario=="X"){
                this.$router.push('/')
            }
        },
        contarSemana(result){
            var docRef = db.collection("capacitaciones").where("semanaFecha", "==", result);
            docRef.get().then(doc => {
                console.log(doc.docs.length)
                var largo=doc.docs.length
                if(largo>=2){
                        Swal.fire({
                        title: "Aviso",
                        text: "Ya existen dos capacitaciones agendadas para esta semana, si es posible seleccione otra.",
                        icon: "info",
                        });
                        
                    }
            }).catch(function(error) {
            console.log("Error getting document:", error);
                    });
/*
             db.collection('capacitaciones').where("semanaFecha", "==", result).onSnapshot((snapshotChange) => {
                if(snapshotChange.empty){
                 
                    console.log("no")
                    document.getElementById("btnRegistrar").style.display="block"
                }else{
                    var largo=(snapshotChange.docs.length)
                    if(largo>=3){
                        Swal.fire({
                        title: "Error",
                        text: "Ya existen dos capacitaciones agendadas para esta semana, por favor seleccione otra.",
                        icon: "error",
                        });
                        document.getElementById("btnRegistrar").style.display="none"
                    }else{
                        document.getElementById("btnRegistrar").style.display="block"
                    }
                }
               
          })*/
        },
        leer(){
                var rut = document.getElementById("rutF").value;
				var rutx=rut.replace("-","");
				var largo=rutx.length
				//console.log(largo,largo-1)
				var rut2=rutx.slice(0,largo-1)
				var rut3=rutx.slice(largo-1)
                //console.log(rut2+"-"+rut3)
				document.getElementById("rutF").value=(rut2+"-"+rut3)
				
        },
        leer2(){
                var rut = document.getElementById("rutProveedor").value;
				var rutx=rut.replace("-","");
				var largo=rutx.length
				//console.log(largo,largo-1)
				var rut2=rutx.slice(0,largo-1)
				var rut3=rutx.slice(largo-1)
                //console.log(rut2+"-"+rut3)
				document.getElementById("rutProveedor").value=(rut2+"-"+rut3)
				
        },
        camposMail(){
            console.log(
            this.capacitacion.persona,
            this.capacitacion.tipo,
            this.capacitacion.lugar,
            this.capacitacion.observacion,
            this.capacitacion.fecha,
            this.capacitacion.hora        
            )
             
        },
        testMail(){
               var templateParams = {
                    usuario: this.capacitacion.persona,
                    lugar: this.capacitacion.lugar,
                    observacion: this.capacitacion.observacion,
                    fecha:this.capacitacion.fecha,
                    hora:this.capacitacion.hora,
                    proveedor:this.nombreProveedor,

                };
                
                emailjs.send('service_7q45ogt', 'template_oalxnug', templateParams)
                    .then(function(response) {
                    console.log('SUCCESS!', response.status, response.text);
                    }, function(error) {
                    console.log('FAILED...', error);
                    }); 
        },
        testMail2(){
               var templateParams = {
                    usuario: this.capacitacion.persona,
                    lugar: this.capacitacion.lugar,
                    //observacion: this.capacitacion.observacion,
                    fecha:this.capacitacion.fecha,
                    hora:this.capacitacion.hora,
                    proveedor:this.nombreProveedor,
                };
                
                emailjs.send('service_7q45ogt', 'template_ipyic65', templateParams)
                    .then(function(response) {
                    console.log('SUCCESS!', response.status, response.text);
                    }, function(error) {
                    console.log('FAILED...', error);
                    }); 
        }
        }
    }
</script>